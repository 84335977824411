import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from '../../config';
import { Link, useParams } from 'react-router-dom';
import LocationModal from '../Components/LocationModal';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import '../Components/Trendings.css'
import PuffLoader from "react-spinners/PuffLoader";// Import your custom loader or spinner

function CategoryPage() {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [Categories, SetCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const { category } = useParams();
    const Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const handleLocationChange = (location) => {
        const city = location;
        setUserLocation(city);
        setShowLocationModal(false);
    };

    const handleShowLocationModal = (openModal) => {
        setShowLocationModal(openModal);
    };

    const getMonthFromDateString = (dateString) => {
        const date = new Date(dateString);
        return date.getMonth();
    };

    const getDayFromDateString = (dateString) => {
        const date = new Date(dateString);
        return date.getDate(); // Corrected to get date (day of the month)
    };

    const lowestTicket = (ticketprices) => {
        let lowestPrice = ticketprices[0].ticket_price;
        for (let i = 1; i < ticketprices.length; i++) {
            if (ticketprices[i].ticket_price !== 0 && lowestPrice > ticketprices[i].ticket_price) {
                lowestPrice = ticketprices[i].ticket_price;
            }
        }
        return lowestPrice;
    };

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                setIsLoading(true); // Start loading before API call
                const response = await axios.get(`${API_URL}/api/category/${category}`);
                const data = response.data;
                SetCategories(data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            } finally {
                setIsLoading(false); // End loading after data is fetched or on error
            }
        };
        fetchCategoryData();
    }, [category]);

    return (
        <div>
            {/* Render Location component as a modal */}
            {showLocationModal && (
                <LocationModal
                    onClose={() => setShowLocationModal(false)}
                    onLocationChange={handleLocationChange}
                />
            )}

            <NavBar userLocation={userLocation} onNext={handleShowLocationModal} />

            {/* Categories */}
            <div className='p-lg-3 p-md-3'>
                <div className='mx-lg-5'>
                    <h3 className='fw-bold'>Event Category : {category}</h3>
                    <hr />
                </div>
                <div className='row trendingeventlist'>
                    {isLoading ? (
                        <div className='d-flex justify-content-center my-5'>
                            <PuffLoader size={48}
                                loading={isLoading} />
                        </div>
                    ) : (
                        Categories.length > 0 ? (
                            Categories.map((event, index) => (
                                <div className='col-lg-4 col-sm-6 col-12' key={index}>
                                    <Link className='link' to={`/productpage/${event._id}`}>
                                        <div className='card trendingCard shadow-sm'>
                                            <div className='card-img-top trendingcardImg'>
                                                <img src={`${API_URL}/uploads/${event?.cover_image}`} className='trendingImg' alt="Event" />
                                            </div>
                                            <div className='card-body trendingcardContent d-flex align-items-center px-lg-4 px-md-2 px-sm-1 px-1'>
                                                <div className='d-flex flex-column text-center me-lg-4 me-sm-2 me-4 ms-3'>
                                                    <span className='month text-primary fw-bold'>{Month[getMonthFromDateString(event?.event_starts_date)]}</span>
                                                    <span className='date fw-bold'>{getDayFromDateString(event?.event_starts_date)}</span>
                                                </div>
                                                <div className='d-flex flex-column'>
                                                    <span className='eventhead fw-bold'>{event?.event_name}</span>
                                                    <span className='eventvenue text-secondary fw-normal'>{event?.event_venue} {event?.event_city}</span>
                                                    <span className='eventprice text-secondary fw-normal'>₹ {event.ticket.length > 0 ? lowestTicket(event.ticket) : "0"} onwards</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))
                        ) : (
                            <p className='text-center fw-bold'>No Trending events Currently</p>
                        )
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default CategoryPage;
