import React, { useEffect, useState } from 'react';
import './Carousel.css';
import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import axios from 'axios';
import { API_URL } from '../../config';
import CaraousalsImages from '../../Assets/CaraousalsImages.js';

function Carousel() {
  const [coverImages, setCoverImages] = useState([]);

  // const getCoverImages = async () => {
  //   try {
  //     const response = await axios.get(`${API_URL}/image`);
  //     setCoverImages(response.data);
  //   } catch (error) {
  //     alert('Error fetching images');
  //   }
  // };

  useEffect(() => {
    // getCoverImages();
    setCoverImages(CaraousalsImages)
  }, []);

  return (
    <div id="carouselExampleIndicators" className="carouselDiv carousel slide" data-bs-ride="carousel">
      {coverImages.length > 0 ? (
        <>
          <div className="carousel-inner innercarouselDiv">
            {coverImages.map((image, index) => (
              <div key={image.id} className={`carousel-item carouselimgDiv px-1 ${index === 0 ? 'active' : ''}`} data-bs-interval="5000">
                <img src={image.image} alt={`Banner ${index + 1}`} className="d-block cover-image" />
              </div>
            ))}
          </div>
        </> 
      ) : (
        <p className='text-center fw-bold'>No events found.</p>
      )}
    </div>
  );
}

export default Carousel;
