import { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import { Button } from "react-bootstrap";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import auth from "../../firebaseConfig";
import apiService from "../../services/apiService";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsXCircle } from "react-icons/bs";
import "./LoginModal.css";
import SyncLoader from "react-spinners/SyncLoader";
import indian_flag from '../../Assets/flag.png'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/* global grecaptcha */

function LoginModal({ onLoginSuccess }) {
  const [phone, setPhone] = useState("");
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [showOTPField, setShowOTPField] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const recaptchaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [usernotLogin, setusernotLogin] = useState(false)


  useEffect(() => {
    let countdown;
    if (isResendDisabled && showOTPField) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(countdown);
            setIsResendDisabled(false);
            return 60;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [isResendDisabled, showOTPField]);

  const setupRecaptcha = () => {
    if (!recaptchaRef.current) {
      console.error("reCAPTCHA element not found.");
      return;
    }

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
        size: "invisible",
        callback: (response) => {
          console.log("Response", response);
        },
        'expired-callback': () => {
          console.log("Recaptcha expired, please try again.");
        }
      });

      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    } else {
      if (typeof grecaptcha !== 'undefined' && window.recaptchaWidgetId) {
        grecaptcha.reset(window.recaptchaWidgetId);
      }
    }
  };

  const sendOTP = async () => {
    setLoading(true);
    setupRecaptcha();
    const phoneNumber = "+91" + phone;
    const appVerifier = window.recaptchaVerifier;
    try {
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      setUser(confirmation);
      setShowOTPField(true);
      setIsResendDisabled(true);
    } catch (err) {
      console.error("Error during OTP send:", err.message);
      if (typeof grecaptcha !== 'undefined' && window.recaptchaWidgetId) {
        grecaptcha.reset(window.recaptchaWidgetId);
      }
      alert(`Failed to send OTP. Error: ${err.message}`);
    } finally {
      setLoading(false); // Reset loading to false after OTP is verified
    }
  };


  const verifyOtp = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const data = await user.confirm(otp);
      if (data) {
        apiService.setData(data.user);
        const isUser = await apiService.get(`/user/getUserWithID/${data.user.phoneNumber}`);
        if (isUser) {
          apiService.setData(isUser);
          onLoginSuccess(); // Close modal and update main state
        } else {
          // window.location.href = "/signup";
          setusernotLogin(true)
        }
      }
    } catch (err) {
      console.error(err);
      alert("Invalid OTP. Please try again.");
    } finally {
      setLoading(false); // Reset loading to false after OTP is verified
    }
  };

  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [renderFlag, setRenderFlag] = useState("");
  const location = useLocation();
  const userData = apiService.getData();

  const handleDateChange = (date) => {
    setDob(date);
  };

  const saveUserandProceed = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const body = {
        _id: userData.uid,
        phone: userData.phoneNumber,
        firstName: firstName,
        lastName: lastName,
        email: email,
        gender: gender,
        dob: dob
      };
      const res = await apiService.post('/user/addUser', body);
      if (res.status === 200) {
        // Update local storage with the new user data
        apiService.setData(body);
        onLoginSuccess();

        // switch (renderFlag) {
        //     case "event":
        //         window.location.href = "https://hfs-live-31172.web.app";
        //         break;
        //     case "login":
        //         navigate("/");
        //         break;
        //     case "ticket":
        //         navigate("/paymentgateway");
        //         break;
        //     default:
        //         navigate("/");
        //         break;
        // }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fullscreen-login">
      {
        !usernotLogin && (
          <div className="login-containermodal">
            <span>
              <BsXCircle onClick={onLoginSuccess} className="closebtnX bg-white rounded-pill" />
            </span>
            <span className="bookhead" style={{ marginTop: "-5rem" }}>
              Book <span className="your-fs"> Your Front Seat</span>
            </span>
            <p className="login">Login</p>
            {!showOTPField && (
              <form>
                <div className="div-num">
                  <p className="enter-number">Enter your mobile number</p>
                </div>
                <div className="mb-5">
                  <div className="input-group mb-3">
                    <span className="input-group-text border-primary">
                      <img className="me-2 mt-1" src={indian_flag} alt="India" /> +91
                    </span>
                    <input
                      type="tel"
                      className="form-control mobileinput border-primary"
                      name="mobileNumber"
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>

                  {/* <input
                className="form-control mobileinput py-3 border-primary"
                type="tel"
                name="mobileNumber"
                placeholder="+91"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              /> */}
                </div>
                {/* <Button onClick={sendOTP} className="btn getotpbtn btn-primary w-50">
              Get OTP
            </Button> */}
                <Button onClick={sendOTP} className="btn getotpbtn btn-primary w-50" disabled={loading}>
                  {loading ? <SyncLoader animation="border" color="#FFFF" size="10" speedMultiplier={1} margin={4} /> : "Get OTP"} {/* Loader for Get OTP */}
                </Button>
              </form>
            )}

            {showOTPField && (
              <>
                <div className="div-num">
                  <p className="enter-number">Enter OTP</p>
                </div>
                <TextField
                  className="form-control mb-5"
                  onChange={(e) => setOtp(e.target.value)}
                  variant="outlined"
                  placeholder="xxxxxx"
                />
                <br />
                <div>
                  {/* <Button className="btn btn-primary" onClick={verifyOtp}>
                Login
              </Button> */}
                  <Button className="btn btn-primary" onClick={verifyOtp} disabled={loading}>
                    {loading ? <SyncLoader animation="border" color="#FFFF" size="10" speedMultiplier={1} margin={4} /> : "Login"} {/* Loader for Login */}
                  </Button>
                </div>
                <div className="resend-otp">
                  <Link
                    className={`resend-text ${isResendDisabled ? "isResendDisabled" : "isResendEnabled"}`}
                    onClick={sendOTP}
                    disabled={isResendDisabled}
                  >
                    Resend OTP
                  </Link>
                  {timer === 60 ? "" : <span className="countdown"> in {timer} seconds</span>}
                </div>
              </>
            )}
          </div>
        )
      }
      {
        usernotLogin && (
          <>
            <div className="personaldet" id="desktopView">
              <div className="containerr h-100">
                <div className="cont-pers">
                  <span>
                    <BsXCircle onClick={onLoginSuccess} className="closebtn bg-white rounded-pill" />
                  </span>
                  <div className="row p-3">
                    <div>
                      <h5 className="mt-3">
                        <span className="fw-bold">Book</span> <span className="your-fs"> Your Front Seat</span>
                      </h5>
                    </div>
                    <h4 className="fw-bold text-start mt-3">Personal Details</h4>
                    <div>
                      <input
                        className="form-control border-primary mb-3"
                        type="text"
                        id="first-name"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control border-primary mb-3"
                        type="text"
                        id="last-name"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                    <div>
                      <input
                        className="form-control border-primary"
                        type="text"
                        id="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <p className="mail-recieve">The email will receive the m-ticket</p>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-6 text-start">
                        <div className="d-flex justify-content-between">
                          <div className="gender">
                            <input
                              className="pers-selection"
                              type="radio"
                              id="female"
                              name="gender"
                              value="female"
                              checked={gender === "female"}
                              onChange={() => setGender("female")}
                            />
                            <label className="pers-gender" htmlFor="female">
                              Female
                            </label>
                          </div>
                          <div className="gender">
                            <input
                              className="pers-selection"
                              type="radio"
                              id="male"
                              name="gender"
                              value="male"
                              checked={gender === "male"}
                              onChange={() => setGender("male")}
                            />
                            <label className="pers-gender" htmlFor="male">
                              Male
                            </label>
                          </div>
                        </div>
                        {/* <div className="d-flex justify-content-center">
                          <div className="gender m-auto">
                            <input
                              className="pers-selection"
                              type="radio"
                              id="other"
                              name="gender"
                              value="other"
                              checked={gender === "other"}
                              onChange={() => setGender("other")}
                            />
                            <label className="pers-gender" htmlFor="others">
                              Others
                            </label>
                          </div>
                        </div> */}
                      </div>
                      <div className="col-lg-6 col-md-6 col-6">
                        <DatePicker
                          className="form-control border-primary ms-lg-2"
                          selected={dob}
                          id="dob"
                          onChange={handleDateChange}
                          placeholderText="DOB    DD/MM/YYYY"
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </div>
                    {/* Progress Bar */}
                    {isLoading && (
                      <div className="progress-bar-container">
                        <div className="progress-bar"></div>
                      </div>
                    )}

                    <div className="BtnDiv">
                      <button className="pers-pay-btn" onClick={saveUserandProceed}>
                        {
                          (renderFlag === "ticket") ? <span>Proceed to Pay</span>
                            : <span>Register Yourself</span>
                        }

                      </button>
                    </div>
                  </div>
                  <div className="pers-accept-div text-center">
                    {/* <input type="checkbox" id="terms-pers" /> */}
                    <label htmlFor="terms" className="text-terms-acc">
                      I accept the Terms of Use & Privacy Policy
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="personaldet" id="mobileView">
              <div className="containerr h-100">
                <div className="cont-pers">
                  <span onClick={onLoginSuccess}>
                    <BsXCircle className="closebtn bg-white rounded-pill" />
                  </span>
                  <div>
                    <p className="text-center"><span className="book">Book </span><span className="front-seat fw-semibold"> Your Front Seat</span></p>
                    <p className="heading fw-semibold">Personal Details</p>
                    <form>
                      <div>
                        <input
                          className="form-control border-primary mb-3"
                          type="text"
                          id="first-name"
                          placeholder="First Name"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          style={{ height: "50px" }}
                        />
                      </div>
                      <div>
                        <input
                          className="form-control border-primary mb-3"
                          type="text"
                          id="last-name"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          style={{ height: "50px" }}
                        />
                      </div>
                      <div>
                        <input
                          className="form-control border-primary"
                          type="text"
                          id="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          style={{ height: "50px" }}
                        />
                      </div>
                      <div className="d-flex justify-content-between" style={{ marginTop: "29px", marginBottom: "23px" }}>
                        <div>
                          <input
                            className="pers-selection"
                            type="radio"
                            id="female"
                            name="gender"
                            value="female"
                            checked={gender === "female"}
                            onChange={() => setGender("female")}
                          />
                          <label className="pers-gender" htmlFor="female">
                            Female
                          </label>
                        </div>
                        <div>
                          <input
                            className="pers-selection"
                            type="radio"
                            id="male"
                            name="gender"
                            value="male"
                            checked={gender === "male"}
                            onChange={() => setGender("male")}
                          />
                          <label className="pers-gender" htmlFor="male">
                            Male
                          </label>
                        </div>
                        <div>
                          <input
                            className="pers-selection"
                            type="radio"
                            id="other"
                            name="gender"
                            value="other"
                            checked={gender === "other"}
                            onChange={() => setGender("other")}
                          />
                          <label className="pers-gender" htmlFor="others">
                            Others
                          </label>
                        </div>
                      </div>
                      <div className="row" style={{ marginBottom: "45px" }}>
                        <DatePicker
                          className="form-control border-primary ms-lg-2"
                          selected={dob}
                          id="dob"
                          onChange={handleDateChange}
                          placeholderText="DOB    DD/MM/YYYY"
                          dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                      <button className="pers-pay-btn" type="submit" onClick={saveUserandProceed}>
                        {
                          (renderFlag === "ticket") ? <span>Proceed to Pay</span>
                            : <span>Register Yourself</span>
                        }

                      </button>
                      <span className="pers-accept-div">
                        {/* <input type="checkbox" id="terms-pers" /> */}
                        <label htmlFor="terms" className="text-terms-acc">
                          I accept the Terms of Use & Privacy Policy
                        </label>
                      </span>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
      <div id="recaptcha" ref={recaptchaRef}></div>
    </div>
  );
}

export default LoginModal;
