import React, { useState } from "react";
import './AboutUsPage.css'
import NavBar from "../Components/NavBar";
import LocationModal from "../Components/LocationModal";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Crown from '../../Assets/about us_what we offer-01.png'
import Box from '../../Assets/about us_what we offer-02.png'
import Sound from '../../Assets/about us_what we offer-03.png'
import Puzzle from '../../Assets/about us_what we offer-04.png'

const AboutUsPage = () => {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    const Navigate = useNavigate();
    const handleViewTicket = () => {
        Navigate('/yourtickets');
    }

    return (
        <div>
            <NavBar userLocation={userLocation} onNext={() => setShowLocationModal(true)} />
            {/* Render Location component as a modal */}
            {showLocationModal && (
                <LocationModal
                    onClose={() => setShowLocationModal(false)}
                    onLocationChange={handleLocationChange}
                />
            )}

            {/* Content */}
            <div className="row">
                <div className="aboutHead">
                    <div className="text-center">
                        <h1 className="aboutHeading">About Us</h1>
                        <p className="aboutSubHeading">Because your story deserves the best view.</p>
                    </div>
                </div>

                <div className="text-center px-5">
                    <p className="headingStyle mt-5">
                        Bringing You Closer to the Action with the Best Seats and Hassle Free Booking!
                    </p>

                    <p className="paraStyle">
                        At My Front Seat, we connect you with the events you love and ensure you get the best seat in the house. Whether it's a thrilling concert, an exciting sports match, or an inspiring workshop, we make ticket-buying simple and secure. With our user-friendly platform and top-notch security, you can focus on enjoying the event while we handle the details. Get ready to dive into your favourite experiences effortlessly!
                    </p>


                    <p className="headingStyle">
                        Our Story
                    </p>

                    <p className="paraStyle">
                        To make your event experiences unforgettable. We saw that finding the best seats and securing tickets could be a hassle, so we created My Front Seat to simplify the process. Whether it’s a thrilling concert, an exciting sports game, or an inspiring workshop, we’re here to connect you with the events you love. With a focus on seamless booking and top-notch security, our team is dedicated to making sure you get the best seat and enjoy every moment. Join us as we bring you closer to the action and make every event exceptional.
                    </p>



                    <p className="headingStyle">
                        What We Offer
                    </p>

                    <div className="d-flex justify-content-between mx-3">
                        <div className="text-center">
                            <img className="about_img" src={Crown}></img>
                            <p className="paraStyle">VIP Experience</p>
                        </div>

                        <div className="text-center">
                            <img className="about_img" src={Puzzle}></img>
                            <p className="paraStyle">Personalised Picks</p>
                        </div>
                        <div className="text-center">
                            <img className="about_img" src={Sound}></img>
                            <p className="paraStyle">Marketing Strategy</p>
                        </div>
                        <div className="text-center">
                            <img className="about_img" src={Box}></img>
                            <p className="paraStyle">Pricing Strategy</p>
                        </div>
                    </div>

                </div>
            </div>


            <Footer />
        </div>
    )
}

export default AboutUsPage;