import React from "react";
import { Link } from "react-router-dom"
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import Logo from '../../Assets/LogoUser.png'
import "./Footer.css";

const Footer = () => {
    const userLoggedIn = JSON.parse(localStorage.getItem('userData'));
    return (
        <div className="row footerDiv">
            <div className="row">
                <div className="col-lg-2 footerLogo">
                    <img className='footerlogoImg'
                        src={Logo}
                    />
                </div>
                <div className="col-lg-2 col-md-3 col-sm-6 col-6 footerCols">
                    <ul className="footerList">
                        <li className="footerHeader">Customers</li>
                        <li className="footerContents">Customer Support</li>
                        <li className="footerContents">
                        {
                                userLoggedIn? 
                                <Link className="link" to='/yourtickets'>Your Tickets</Link>:
                                <Link className="link" to='/login'>Your Tickets</Link>
                            }
                            </li>
                        <li className="footerContents">
                        {
                                userLoggedIn? 
                                <Link className="link" to='/userprofile'>Your Profile</Link>:
                                <Link className="link" to='/login'>Your Profile</Link>
                            }
                            </li>
                    </ul>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-6 col-6 footerCols">
                    <ul className="footerList">
                        <li className="footerHeader">Event Organisers</li>
                        <li className="footerContents">
                            {
                                userLoggedIn? 
                                <Link className="link" to='/list-your-event'>List Your Event</Link>:
                                <Link className="link" to='/login'>List Your Event</Link>
                            }
                            </li>
                        <li className="footerContents">Contact Us</li>
                        <li className="footerContents">Ticket Scanner</li>
                    </ul>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-6 col-6 footerCols">
                    <ul className="footerList">
                        <li className="footerHeader">Company</li>
                        <li className="footerContents"><Link className="link" target="blank" to='/aboutus'>About Us</Link></li>
                        <li className="footerContents">Blog</li>
                        <li className="footerContents"><Link className="link" target="blank" to='/policy'>Privacy Policy</Link></li>
                        <li className="footerContents"><Link className="link" target="blank" to='/terms-condition'>Terms & Conditions</Link></li>
                    </ul>
                </div>
                <div className="col-lg-2 col-md-3 col-sm-6 col-6 footerCols">
                    <ul className="footerList">
                        <li className="footerHeader">Contact Us</li>
                        <li className="footerContents">info@myfrontseat.in</li>
                        <li className="footerContents">+91 7028260520</li>
                        <li className="footerContents">107, Dighori, Umrer Road, Nagpur 440034 </li>
                    </ul>
                </div>
                <div className="col-lg-2 col-md-12 col-sm-12 col-12 footerColsm">
                    <ul className="footerList">
                        <li className="footerHeader">Socials</li>
                        <li className="footerContentsm"><AiFillInstagram size={18} /> instagram</li>
                        <li className="footerContentsm facebook"><AiFillFacebook size={18} /> facebook</li>
                    </ul>
                </div>
            </div>
            <div style={{ background: "#04092C", color: "#F2F6F9", textAlign: "center" }}>
                <p className="footer-footer">&copy; 2024 myfrontseat.in</p>
            </div>
        </div>
    );
};

export default Footer;
